import React from "react";
import GatsbyImage from "gatsby-image/withIEPolyfill";

const PreviewCompatibleImage = ({
  imageData,
  alt = "Philips Academy",
  gatsbyImageProps = {},
  aspectRatio,
  ...props
}) => {
  if (!gatsbyImageProps.loading) {
    gatsbyImageProps.loading = "auto";
  }
  if (imageData && imageData.childImageSharp) {
    const fluid = aspectRatio
      ? { ...imageData.childImageSharp.fluid, aspectRatio }
      : imageData.childImageSharp.fluid;
    return (
      <GatsbyImage
        fluid={fluid}
        alt={alt}
        style={{ height: "100%" }}
        {...gatsbyImageProps}
        {...props}
      />
    );
  } else if (imageData && typeof imageData === "string") {
    return (
      <img src={imageData} alt={alt} style={{ width: "100%" }} {...props} />
    );
  }
  return null;
};

export default PreviewCompatibleImage;
